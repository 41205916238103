import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Metadata from "../components/Metadata";

const IndexPage = () => {
  // Client-side Runtime Data Fetching
  const [languageArr, setLanguageArr] = useState([]);

  useEffect(() => {
    jsonp(
      "https://wakatime.com/share/@kvaishak/6baeee19-7699-495c-b760-8be89c93efc0.json"
    ).then((response) => {
      setLanguageArr(response.data);
    });

    //using jsonp to fetch the wakatime embedable stats -> Refered from here (https://blog.logrocket.com/jsonp-demystified-what-it-is-and-why-it-exists/)
    function jsonp(url, timeout = 7500) {
      const head = document.querySelector("head");

      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        const callbackName = "jsonpCallback";

        script.src = encodeURI(`${url}?callback=${callbackName}`);
        script.async = true;

        const timeoutId = window.setTimeout(() => {
          cleanUp();

          return reject(new Error("Timeout"));
        }, timeout);

        window[callbackName] = (data) => {
          cleanUp();

          return resolve(data);
        };

        script.addEventListener("error", (error) => {
          cleanUp();

          return reject(error);
        });

        function cleanUp() {
          window[callbackName] = undefined;
          head.removeChild(script);
          window.clearTimeout(timeoutId);
          script = null;
        }

        head.appendChild(script);
      });
    }
  }, []);

  return (
    <div className="container">
      <Metadata title="Now" description="Whats keeping me busy these days." />

      <Header />

      <article>
        <h3 className="main">What I'm Doing Now</h3>

        <p>
          I am currently working as a Front-end Developer for Liqid Investments,
          Berlin.
        </p>

        <p>
          I love to work on new frameworks and technologies which grabs my
          attention. Currently I am fascinated about{" "}
          <a href="https://golang.org/">Golang</a> and am working on an
          interesting project with React and Firebase.
        </p>

        <p>
          {console.log(languageArr)}
          Javascript is my go to language of choice. But that doesnt stop me
          from wading my legs through unknown territory.
          {languageArr.length > 0 &&
          languageArr[0].name !== "Other" &&
          languageArr[0].percent > 0 ? (
            <span>
              {" "}
              According to <a href="https://wakatime.com/"> Wakatime </a>,{" "}
              <b>{languageArr[0].name}</b> was my top used language accounting
              to {languageArr[0].percent}% of time, where as my second used
              language was <b>{languageArr[1].name}</b> with a{" "}
              {languageArr[1].percent}% usage.{" "}
            </span>
          ) : null}
        </p>

        <p>
          When not distracted by pixels, I love reading books and getting lost
          in the pursuit of tools for thought.
        </p>
      </article>

      <Footer />
    </div>
  );
};

export default IndexPage;
